import { Scene, Vector3, Mesh, MeshBuilder } from "babylonjs";
import { sortBy, map, zip, dropRight, drop, last, first, min } from "lodash";
import { IFront, ISegment, EFrontType } from "../store/state";
import {
  calcBackMesh,
  calcCuboidMesh,
  renderEdge,
  calcFrontMesh
} from "@/helpers/customMeshBuilder.ts";

export function insertFront(
  S: Scene,
  depth: number,
  path: [number, number][],
  open: boolean,
  { segmentId }: Pick<ISegment, "segmentId">,
  { frontId, kind }: Pick<IFront, "frontId" | "kind">
) {
  let frontMesh!: Mesh;
  switch (kind) {
    case EFrontType.STANDARD_DOOR_L:
    case EFrontType.STANDARD_DOOR_R:
      frontMesh = insertStandardDoor(S, depth, path, { kind });
      break;
    case EFrontType.CORNER_DOOR:
      frontMesh = insertCornerDoor(S, depth, path);
  }

  frontMesh.parent = S.getMeshByName(segmentId);
  frontMesh.name = frontId;
  frontMesh.position.z = depth;
  if (open) {
    kind == EFrontType.STANDARD_DOOR_R
      ? frontMesh.rotate(new Vector3(0, 1, 0), (-1 * Math.PI) / 2.2, BABYLON.Space.LOCAL)
      : frontMesh.rotate(new Vector3(0, 1, 0), Math.PI / 2.2, BABYLON.Space.LOCAL);
  }
  return frontMesh;
}

export function deleteFront(S: Scene, { frontId }: Pick<IFront, "frontId">) {
  const maybeMesh = S.getMeshByName(frontId);
  if (maybeMesh) {
    maybeMesh.dispose();
  }
}

export function insertStandardDoor(
  S: Scene,
  depth: number,
  path: [number, number][],
  { kind }: Pick<IFront, "kind">
) {
  const pathtmp = sortBy(path, o => o[0]);
  const frontMesh = calcFrontMesh(S, pathtmp);
  const handlePos = {
    x: kind !== EFrontType.STANDARD_DOOR_R ? 5 : last(path)![0] - 5,
    y: kind == EFrontType.STANDARD_DOOR_L ? 0.5 * path[0][1] : 0.5 * last(path)![1],
    z: 3
  };
  const handle = createHandle(S, handlePos.x, handlePos.y, handlePos.z);
  if (handle) handle.parent = frontMesh;
  if (!frontMesh) throw new Error("Unable to merge Front");
  renderEdge(frontMesh);
  return frontMesh;
}
export function insertCornerDoor(S: Scene, depth: number, path: [number, number][]) {
  const w = last(path)![0];
  const h = last(path)![1];
  if (!w) throw new Error("Invalid Path");
  if (!h) throw new Error("Invalid Path");

  const frontMesh = calcCuboidMesh(S, h - 8, w - depth - 2, 2);
  frontMesh.position.x += depth + 2;
  frontMesh.position.y += 8;
  frontMesh.setPivotPoint(new Vector3((1 / 2) * last(path)![0], 0, 0));
  const handlePos = {
    x: 5,
    y: (1 / 2) * path[0][1] - 8,
    z: 3
  };
  const handle = createHandle(S, handlePos.x, handlePos.y, handlePos.z);
  if (handle) handle.parent = frontMesh;
  return frontMesh;
}

export function createHandle(S: Scene, posX: number, posY: number, posZ: number) {
  const topPart = MeshBuilder.CreateCylinder("rail", { height: 4, diameter: 1 }, S);
  const bottomPart = MeshBuilder.CreateCylinder("rail", { height: 4, diameter: 1 }, S);
  const middlePart = MeshBuilder.CreateCylinder("rail", { height: 10, diameter: 1 }, S);
  middlePart.position = new Vector3(posX, posY, posZ + 2);
  bottomPart.position = new Vector3(posX, posY - 4, posZ);
  topPart.position = new Vector3(posX, posY + 4, posZ);
  topPart.rotate(new Vector3(1, 0, 0), (-1 * Math.PI) / 2, BABYLON.Space.LOCAL);
  bottomPart.rotate(new Vector3(1, 0, 0), (-1 * Math.PI) / 2, BABYLON.Space.LOCAL);
  return Mesh.MergeMeshes([middlePart, bottomPart, topPart]);
}
