





















import { Component, Prop, Vue } from "vue-property-decorator";
//@ts-ignore
import CookieLaw from "vue-cookie-law";

@Component({
  components: {
    CookieLaw
  }
})
export default class cookieConsent extends Vue {
  async revokeCookies() {
    (this.$refs["cookielaw"] as any).revoke();
    (window as any)._paq.push(["forgetCookieConsentGiven"]);
    (window as any)._paq.push(["forgetConsentGiven"]);
    (this.$refs["cookielaw"] as any).open();
    console.log("tracking denied");
  }
  async acceptTracking() {
    (window as any)._paq.push(["rememberConsentGiven"]);
    (window as any)._paq.push(["rememberCookieConsentGiven"]);
    console.log("tracking accepted");
  }
}
