import {
  Scene,
  Vector3,
  Mesh,
  VertexData,
  StandardMaterial,
  FloatArray,
  MeshBuilder,
  Color3
} from "babylonjs";
import { times, map, zip, dropRight, drop, first, last, round, flattenDeep } from "lodash";
import { EDGE_COLOR } from "@/helpers/constants";
import { EFrontType } from "../store/state";
// prettier-ignore
export function createCube(p:number[][], s: Scene) {
  let normals: number[] = [];
  let customMesh = new Mesh("custom", s);
  var mat = new StandardMaterial("mat", s);
  mat.backFaceCulling = false;
  customMesh.material = mat;
  const positions = getPositions(p);
  var indices = [
    0,1,2,
    3,4,5,
    6,7,8,
    9,10,11,
    12,13,14,
    15,16,17,
    18,19,20,
    21,22,23,
    24,25,26,
    27,28,29,
    30,31,32,
    33,34,35
  ];

  var vertexData = new VertexData();
  VertexData.ComputeNormals(positions, indices, normals);
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.applyToMesh(customMesh);
  //var pdata = customMesh.getVerticesData(VertexBuffer.PositionKind);
  //var ndata = customMesh.getVerticesData(VertexBuffer.NormalKind);
  renderEdge(customMesh);
  return customMesh;
}

// prettier-ignore
export function getPositions(p:number[][]){
  const positions: number[][] = [
    //rechts
    p[0],p[1],p[2],
    p[2],p[3],p[0],
    //links
    p[5],p[4],p[6],
    p[6],p[7],p[5],
    //unten
    p[0],p[3],p[4],
    p[4],p[5],p[0],
    //vorne------------------
    p[3],p[2],p[6],
    p[6],p[4],p[3],
    //oben------------------
    p[2],p[1],p[7],
    p[7],p[6],p[2],
    //hinten--------------------
    p[1],p[0],p[5],
    p[5],p[7],p[1],
  ];
  return flattenDeep(positions) as FloatArray;
}

export function copyMesh(s: Scene, mesh: Mesh) {
  const vertexData = new VertexData();
  vertexData.positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
  vertexData.normals = mesh.getVerticesData(BABYLON.VertexBuffer.NormalKind);
  vertexData.colors = mesh.getVerticesData(BABYLON.VertexBuffer.ColorKind);
  vertexData.uvs = mesh.getVerticesData(BABYLON.VertexBuffer.UVKind);
  vertexData.indices = mesh.getIndices();
  let customMesh = new Mesh("custom", s);
  vertexData.applyToMesh(customMesh);
  mesh.dispose();
  return customMesh;
}

export function renderEdge(mesh: Mesh) {
  mesh.enableEdgesRendering(Math.PI / 4, true);
  mesh.edgesWidth = 20.0;
  mesh.edgesColor = EDGE_COLOR;
}
export function createPolygon(s: Scene, shape: Vector3[], depth: number) {
  const mesh = MeshBuilder.ExtrudePolygon("custom", { shape: shape, depth: depth }, s);
  mesh.rotation.x = -Math.PI / 2;
  mesh.bakeCurrentTransformIntoVertices();
  return mesh;
}
/*---------------------BERECHNUNG DER KOORDINATEN---------------------------*/

/**
 *Erstellt ein Quader Mesh
 *
 * @export
 * @param {Scene} s Szene
 * @param {number} height Höhe des Quaders
 * @param {number} width Breite des Quaders
 * @param {number} depth Tiefe des Quaders
 * @returns Mesh
 */
export function calcCuboidMesh(s: Scene, height: number, width: number, depth: number) {
  const p = [
    [0, 0, 0],
    [0, height, 0],
    [0, height, depth],
    [0, 0, depth],
    [width, 0, depth],
    [width, 0, 0],
    [width, height, depth],
    [width, height, 0]
  ];
  const mesh = createCube(p, s);
  return mesh;
}
export function calcLeftWall(s: Scene, path: [number, number][], width: number, depth: number) {
  const r = calcLinearPointAtX(path, path[1][0] - width);
  const l = calcLinearPointAtX(path, path[1][0]);

  const p = [
    [path[1][0] - width, 0, 0],
    [path[1][0] - width, r[1] - 2, 0],
    [path[1][0] - width, r[1] - 2, depth],
    [path[1][0] - width, 0, depth],
    [path[1][0], 0, depth],
    [path[1][0], 0, 0],
    [path[1][0], l[1] - 2, depth],
    [path[1][0], l[1] - 2, 0]
  ];
  const mesh = createCube(p, s);
  return mesh;
}
/**
 * Berechnet die rechte Segmentwand
 * Bei Schrägen wird die Oberkante entsprechend abgeschrägt
 * @export
 * @param {Scene} s Szene
 * @param {[number, number][]} path Pfadausschnitt in dem die wand ist
 * @param {number} width Dicke der Wand
 * @param {number} depth Tiefe des Segments
 * @returns
 */

// prettier-ignore
export  function calcRightWall(
  s: Scene,
  path: [number, number][],
  width: number,
  depth: number
) {
  const r = calcLinearPointAtX(path, path[0][0]);
  const l = calcLinearPointAtX(path, path[0][0]+width);

  const p = [[0,0,0],[0,r[1]-2,0],[0,r[1]-2,depth],[0,0,depth],
            [2,0,depth],[2,0,0],[2,l[1]-2,depth],[2,l[1]-2,0]]
  const mesh= createCube(p,s);

  return mesh;
}

/**
 * Berechnet ein Mesh für die Rückwand oder Tür
 *
 * @export
 * @param {Scene} s Szene
 * @param {[number, number][]} path Kompletter Pfad eines Segments
 * @param {number} depth Dicke der Rückwand
 * @returns Mesh der Rückwand/Tür
 */
export function calcBackMesh(s: Scene, path: [number, number][], depth: number) {
  const meshes = map(zip(dropRight(path), drop(path)), ([l, r]) => {
    if (!l) throw new Error(`no left path found`);
    if (!r) throw new Error(`no right path found`);

    const p = [
      [l[0], 8, 0],
      [l[0], l[1], 0],
      [l[0], l[1], 2],
      [l[0], 8, 2],
      [r[0], 8, 2],
      [r[0], 8, 0],
      [r[0], r[1], 2],
      [r[0], r[1], 0]
    ];
    const mesh = createCube(p, s);
    return mesh;
  });

  return Mesh.MergeMeshes(meshes);
}

//prettier-ignore
export  function calcFrontMesh(s: Scene, path: [number, number][]) {
  const f = first(path);
  const l = last(path);
  if(!f || !l )throw new Error("Invalid Path")
  let shape:Vector3[]=[];
  map(path,  ([x, y]) => {
    shape.push(new Vector3(x, 0, y))
  });
  shape.push(new Vector3(l[0],0,8));
  shape.push(new Vector3(f[0], 0, 8))
  shape.reverse()
  return createPolygon(s, shape, 2);
}
export function calcTopMesh(s: Scene, path: [number, number][], depth: number) {
  const groupedPath = zip(dropRight(path), drop(path));
  const meshes = map(groupedPath, ([l, r]) => {
    //log.debug("create Top part" + tmpMesh.name);
    if (!l) throw new Error(`no left path found`);
    if (!r) throw new Error(`no right path found`);
    let [l1, r1]: [number, number][] = calcParallelPath(path, [l, r], 2);

    //Randwerte des Paths müssen gesondert behandelt werden
    l1 = l[0] == 0 ? calcLinearPointAtX([l1, r1], round(l1[0], -1)) : l1;
    if (r[1] < l[1] && r[0] != last(path[0])!) r1 = calcLinearPointAtX([l1, r1], round(r1[0], -1));

    const p = [
      [l1[0], l1[1], 2],
      [l[0], l[1], 2],
      [l[0], l[1], depth],
      [l1[0], l1[1], depth],
      [r1[0], r1[1], depth],
      [r1[0], r1[1], 2],
      [r[0], r[1], depth],
      [r[0], r[1], 2]
    ];
    const tmp = createCube(p, s);

    return tmp;
  });
  const mesh = Mesh.MergeMeshes(meshes);
  renderEdge(mesh!);
  return mesh;
}
export function calcDrawerMesh(s: Scene, width: number, depth: number) {
  const [L, R] = times(2, () => calcCuboidMesh(s, 10, 1, depth - 5));
  const widthFTop = (width - 20) * 0.5;
  const base = calcCuboidMesh(s, 1, width, depth - 3);
  const back = calcCuboidMesh(s, 10, width - 1, 2);

  const shape: Vector3[] = [
    new Vector3(0, 0, 0),
    new Vector3(width, 0, 0),
    new Vector3(width, 0, 10),
    new Vector3(width - widthFTop, 0, 10),
    new Vector3(width - widthFTop - 2, 0, 8),
    new Vector3(widthFTop + 2, 0, 8),
    new Vector3(widthFTop, 0, 10),
    new Vector3(0, 0, 10)
  ];
  const front = createPolygon(s, shape, 1);
  front.position = new Vector3(1.5, 1, depth - 2);
  L.position = new Vector3(width, 1, 3);
  R.position = new Vector3(2, 1, 3);
  back.position = new Vector3(2, 1, 1);
  base.position = new Vector3(1.5, 1, 1);
  const mesh = Mesh.MergeMeshes([base, back, L, R]) as Mesh;
  front.parent = mesh;
  renderEdge(front);
  renderEdge(mesh);
  return mesh;
}
export function calcScale(s: Scene, points: Vector3[], orientation: Vector3) {
  const line = MeshBuilder.CreateLines("lines", { points }, s);
  const line2 = MeshBuilder.CreateLines(
    "lines",
    { points: [new Vector3(0, 0, 0), new Vector3(4, 0, 0)] },
    s
  );
  const line3 = MeshBuilder.CreateLines(
    "lines",
    { points: [new Vector3(0, 0, 0), new Vector3(4, 0, 0)] },
    s
  );

  line2.parent = line;
  line3.parent = line;
  line2.position = points[0];
  line3.position = points[1];
  if (orientation.x != 0 || orientation.z != 0) {
    line2.rotation.z = -Math.PI / 2;
    line3.rotation.z = -Math.PI / 2;
    line2.position.y += 2;
    line3.position.y += 2;
  }
  if (orientation.y != 0) {
    line2.position.x -= 2;
    line3.position.x -= 2;
  }
  line.color = new Color3(0, 0, 0);
  line2.color = new Color3(0, 0, 0);
  line3.color = new Color3(0, 0, 0);
  return line;
}
/*--------------------MATHEMATIK FÜR DIE SCHRÄGEN---------------------------------------------------*/

/**
 * Berechnet eine Geradengleichung Parallel zum Pfad in festem abstand d
 *
 * @export
 * @param {[number, number][]} globalPath
 * @param {[number, number][]} path
 * @param {number} d
 * @returns
 */
export function calcParallelPath(
  globalPath: [number, number][],
  path: [number, number][],
  d: number
) {
  const p1 = first(path);
  const p2 = last(path);
  if (!p1 || !p2) throw new Error("Invalid Path");

  const m = calcSlope(p1, p2);
  const normal: [number, number] = [
    (-m / Math.sqrt(m * m + 1)) * d,
    (1 / Math.sqrt(m * m + 1)) * d
  ];

  return [[p1[0] - normal[0], p1[1] - normal[1]], [p2[0] - normal[0], p2[1] - normal[1]]] as [
    number,
    number
  ][];
}
export function calcSlope(p1: [number, number], p2: [number, number]) {
  return (p2[1] - p1[1]) / (p2[0] - p1[0]);
}
export function calcLinearPointAtX(path: [number, number][], x: number) {
  const p1 = first(path);
  const p2 = last(path);
  if (!p1 || !p2) throw new Error("Invalid Path");

  const m = calcSlope(p1, p2);
  const n = p1[1] - m * p1[0];
  return [x, m * x + n] as [number, number];
}
