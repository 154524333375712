import Vue from "vue";
import Vuex, { Store, mapActions } from "vuex";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state, { IState } from "./state";

Vue.use(Vuex);

export default new Vuex.Store<IState>({
  strict: process.env.NODE_ENV !== "production",
  state: () => state,
  getters,
  mutations,
  actions
});
