import * as earcut from "earcut";
import * as GUI from "babylonjs-gui";
(window as any).earcut = earcut;
(window as any).GUI = GUI;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "spectre.css";
import "spectre.css/dist/spectre-exp.min.css";
import "spectre.css/dist/spectre-icons.css";
import "typeface-roboto/index.css";
import "typeface-roboto/index.css";
// @ts-ignore Es existiert kein @types Package
//import VueMatomo from "vue-matomo";

/*Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: "https://logging.sites.sepia.de",
  siteId: 1,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: "matomo",

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  trackerUrl: "https://einbauschrank.shop/actions/matomo.php",

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  trackerScriptUrl: "https://einbauschrank.shop/actions/matomo.js",

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: true,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: false,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: undefined,

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: []
});*/

/*store.subscribe(mutation =>
  // Tracke alle Store Mutations
  (window as any)._paq.push(["trackEvent", mutation.type, JSON.stringify(mutation.payload)])
);*/

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
