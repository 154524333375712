












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HeaderComp extends Vue {
  push(route: string) {
    this.$router.push(route);
  }
}
