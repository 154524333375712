import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Impressum from "./views/Impressum.vue";
import Datenschutz from "./views/Datenschutz.vue";
import Kontakt from "./views/Kontakt.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/datenschutz",
      name: "datenschutz",
      component: Datenschutz
    },
    {
      path: "/kontakt",
      name: "kontakt",
      component: Kontakt
    },
    {
      path: "/impressum",
      name: "impressum",
      component: Impressum
    },
    {
      path: "/step/:step",
      props: true,
      name: "Step",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("./views/Step.vue")
    },
    {
      path: "*",
      name: "Error",
      redirect: "/"
      // component: Home,
    }
  ]
});
