import { find, uniqueId, get, forEach, filter } from "lodash";
import { Vector2, GenericController } from "babylonjs";

import { MutationTree } from "vuex";
import state, { ESegType, EFrontType, IBlock, ISegment, IFront, IState } from "./state";

const mutations: MutationTree<typeof state> = {
  CHANGE_GENERAL_DIMENSIONS(state, payload: Partial<IState["general_dimensions"]>) {
    state.general_dimensions = {
      ...state.general_dimensions,
      ...payload
    };
  },
  SET_FRONT_OPEN(state, { open }: { open: boolean }) {
    state.general_dimensions.front_open = open;
  },
  SET_INNER_WIDTH(state, { segmentId, innerWidth }: { segmentId: string; innerWidth: number }) {
    const segment = find(state.segments, s => s.segmentId === segmentId);
    if (!segment) {
      throw new Error(`Unknown SegmentId ${segmentId}.`);
    }
    segment.innerWidth = innerWidth;
  },
  ADD_SEGMENT(state, { SKind, width }: { SKind: ESegType; width?: number }) {
    const w = width ? width : get(state.segments, ["0", "dimension", "width"], 60);
    const id = uniqueId("segment");
    if (SKind === ESegType.STANDARD_SEGMENT) {
      state.segments.unshift({
        kind: SKind,
        segmentId: id,
        selected: false,
        width: w,
        innerWidth: 57
      });
    } else if (SKind === ESegType.EXTENSION_SEGMENT || SKind === ESegType.CORNER_SEGMENT) {
      state.segments.push({
        kind: SKind,
        segmentId: id,
        selected: false,
        width: w,
        innerWidth: 57
      });
    }
  },
  ADD_BLOCK(state, { segmentId, offset, kind }: Pick<IBlock, "segmentId" | "offset" | "kind">) {
    state.blocks.push({
      blockId: uniqueId(kind),
      segmentId: segmentId,
      selected: false,
      offset: offset,
      height: 10,
      kind: kind
    });
  },
  ADD_FRONT(
    state,
    {
      segmentId,
      segKind,
      kind
    }: { segmentId: ISegment["segmentId"]; segKind: ISegment["kind"]; kind: IFront["kind"] }
  ) {
    const k = segKind === ESegType.CORNER_SEGMENT ? EFrontType.CORNER_DOOR : kind;
    state.foreground.push({
      kind: k,
      segmentId: segmentId,
      frontId: uniqueId(kind)
    });
  },
  REMOVE_SEGMENT(state, { segmentId }: { segmentId: string }) {
    state.segments = filter(state.segments, s => s.segmentId !== segmentId);
  },
  REMOVE_BLOCK(state, { blockId }: { blockId: string }) {
    state.blocks = filter(state.blocks, s => s.blockId !== blockId);
  },
  REMOVE_SEGMENT_BLOCKS(state, { segmentId }: { segmentId: string }) {
    state.blocks = filter(state.blocks, b => b.segmentId !== segmentId);
  },

  REMOVE_SEGMENT_FRONT(state, { segmentId }: { segmentId: string }) {
    state.foreground = filter(state.foreground, f => f.segmentId !== segmentId);
  },
  CHANGE_WIDTH(
    state,
    { id, width }: { id: ISegment["segmentId"]; width: Partial<ISegment["width"]> }
  ) {
    const segment = find(state.segments, s => s.segmentId === id);
    if (!segment) {
      throw new Error(`Unknown SegmentId ${id}.`);
    }
    segment.width = width;
  },
  CHANGE_BLOCK_OFFSET(state, { blockId, offset }: Pick<IBlock, "blockId" | "offset">) {
    const block = find(state.blocks, s => s.blockId === blockId);
    if (!block) {
      throw new Error(`Unknown blockId ${blockId}.`);
    }
    block.offset = offset;
  },
  CHANGE_FRONT_TYPE(state, { segmentId, FType }: { segmentId: string; FType: EFrontType }) {
    const front = find(state.foreground, f => f.segmentId === segmentId);
    if (!front) {
      throw new Error(`Unknown blockId ${segmentId}.`);
    }
    front.kind = FType;
  },
  CHANGE_SEGMENT_KIND(state, { segmentId, kind }: { segmentId: string; kind: ESegType }) {
    const Segment = find(state.segments, s => s.segmentId === segmentId);
    if (!Segment) {
      throw new Error(`Unknown SegId ${segmentId}.`);
    }
    Segment.kind = kind;
  },
  CHANGE_SELECTED_SEGMENT(state, { id }: { id: ISegment["segmentId"] }) {
    forEach(state.segments, segment => {
      segment.segmentId == id ? (segment.selected = true) : (segment.selected = false);
    });
  },
  RESET_STATE(state, { type }: { type: string }) {
    state.segments = [
      {
        kind: ESegType.STANDARD_SEGMENT,
        segmentId: uniqueId("segment"),
        selected: true,
        width: 60,
        innerWidth: 56
      }
    ];
    type != "standard"
      ? state.segments.push({
          kind: ESegType.STANDARD_SEGMENT,
          segmentId: uniqueId("segment"),
          selected: false,
          width: 60,
          innerWidth: 57
        })
      : 0;
    state.general_dimensions = {
      depth: 60,
      height_base: 180,
      height_left: type != "slopeLeft" ? 180 : 60,
      height_right: type != "slopeRight" ? 180 : 60,
      width_left: type != "slopeLeft" ? 0 : 50,
      width_right: type != "slopeRight" ? 0 : 50,
      front_open: false
    };
    state.blocks = [];
    state.foreground = [];
  }
};

export default mutations;
