import { storeAction } from "../storeHelper";
import * as GUI from "babylonjs-gui";
import { FancySceneSingleton } from "@/helpers/sceneSingleton";
import { ESegType, ISegment, EFrontType } from "../state";
import {
  Vector3,
  Mesh,
  Scene,
  Color3,
  Animation,
  StandardMaterial,
  Texture,
  PointerDragBehavior,
  MeshBuilder,
  UtilityLayerRenderer,
  Color4
} from "babylonjs";
import {
  deleteSegment,
  createSegmentMeshes,
  positionSegmentstoParent,
  positionCornerSegment,
  makeSegmentsClickable
} from "@/helpers/segment";
import { insertBlock, deleteBlock } from "@/helpers/block";
import { deleteFront, insertFront } from "@/helpers/front";
import { clearScene, createScale } from "@/helpers/storehelper";
import { forEach, last, map, first, isEmpty, round, toString } from "lodash";
import { EDGE_COLOR } from "@/helpers/constants";

/**
 * Löscht und baut alle Meshes neu auf
 *
 */
export const COMPLETE_REBUILD: storeAction = async ({ dispatch }) => {
  const sc = await FancySceneSingleton.getScene();
  const ui = await FancySceneSingleton.getUI();
  // Können nicht direkt iterieren da dispose
  // das meshes Array mutiert.
  clearScene(sc);
  clearScene(UtilityLayerRenderer.DefaultKeepDepthUtilityLayer.utilityLayerScene);
  await dispatch("REBUILD_SEGMENTS");
  await dispatch("REBUILD_BLOCKS");
  await dispatch("REBUILD_FRONT");
  await dispatch("SET_INNER_WIDTH");
};

/**
 * Löscht und baut Segment Meshes neu auf
 *
 */
export const REBUILD_SEGMENTS: storeAction = async ({
  dispatch,
  getters,
  commit,
  state: {
    segments,
    general_dimensions: { depth, height_base }
  }
}) => {
  const sc = await FancySceneSingleton.getScene();
  forEach(segments, s => deleteSegment(sc, s));
  createSegmentMeshes(sc, getters.segmentsWithProfile, depth, getters.getInnerSegmentWidth);
  makeSegmentsClickable(sc, segments, id => dispatch("SEGMENT_ONCLICK_CB", { id }));
  positionSegmentstoParent(sc, getters.getWingSegments(ESegType.STANDARD_SEGMENT));
  if (!isEmpty(getters.getWingSegments(ESegType.EXTENSION_SEGMENT))) {
    positionCornerSegment(
      sc,
      last(getters.getWingSegments(ESegType.STANDARD_SEGMENT)) as ISegment,
      first(getters.getWingSegments(ESegType.EXTENSION_SEGMENT)) as ISegment,
      getters.getCornerSegment,
      depth
    );
    positionSegmentstoParent(sc, getters.getWingSegments(ESegType.EXTENSION_SEGMENT));
  }
  await dispatch("RENDER_SCALE");
  await dispatch("ADD_SELECTED_HIGHLIGHT", { sc });
  //dispatch("SET_TEXTURES", { S: sc });
};
export const RENDER_SCALE: storeAction = async ({
  dispatch,
  getters,
  commit,
  state: {
    segments,
    general_dimensions: { height_base, depth }
  }
}) => {
  const ul = UtilityLayerRenderer.DefaultKeepDepthUtilityLayer;
  let UI = await FancySceneSingleton.getUI();
  //Scene und Controls clearen
  clearScene(ul.utilityLayerScene);
  while (UI._linkedControls.length) {
    (UI._linkedControls[0] as any).isDisposing = true;
    UI._linkedControls[0].dispose();
  }
  //accumulator zur berechnung der scale positionen [standard wing, extention wing]
  let acc = [0, 0];
  //Scale für die einzelnen Segmente => Unterscheidung zwischen Standard und Eckflügel
  forEach(segments, segment => {
    const points =
      segment.kind === ESegType.STANDARD_SEGMENT
        ? [new Vector3(acc[0], -5, depth), new Vector3(acc[0] + segment.width, -5, depth)]
        : [new Vector3(acc[0], -5, acc[1]), new Vector3(acc[0], -5, acc[1] + segment.width)];
    createScale(ul.utilityLayerScene, points, UI, new Vector3(0, 15, 0));
    segment.kind === ESegType.STANDARD_SEGMENT
      ? (acc[0] += segment.width)
      : (acc[1] += segment.width);
  });
  if (getters.getCornerSegment) {
    createScale(
      ul.utilityLayerScene,
      [
        new Vector3(0, height_base + 5, 0),
        new Vector3(getters.getWingWidth(ESegType.STANDARD_SEGMENT) + depth, height_base + 5, 0)
      ],
      UI,
      new Vector3(0, -15, 0)
    );
    createScale(
      ul.utilityLayerScene,
      [
        new Vector3(getters.getWingWidth(ESegType.STANDARD_SEGMENT) + depth, height_base + 5, 0),
        new Vector3(
          getters.getWingWidth(ESegType.STANDARD_SEGMENT) + depth,
          height_base + 5,
          acc[1]
        )
      ],
      UI,
      new Vector3(0, -15, 0)
    );
  } else {
    createScale(
      ul.utilityLayerScene,
      [
        new Vector3(0, height_base + 5, 0),
        new Vector3(getters.getWingWidth(ESegType.STANDARD_SEGMENT), height_base + 5, 0)
      ],
      UI,
      new Vector3(0, -15, 0)
    );
  }
  createScale(
    ul.utilityLayerScene,
    [new Vector3(-5, 0, 0), new Vector3(-5, height_base, 0)],
    UI,
    new Vector3(20, 0, 0)
  );
};

/**
 * Löscht und baut Block Meshes neu auf
 *
 */
export const REBUILD_BLOCKS: storeAction = async ({
  dispatch,
  commit,
  getters,
  state: {
    blocks,
    general_dimensions: { depth, height_base }
  }
}) => {
  const sc = await FancySceneSingleton.getScene();
  if (blocks) {
    forEach(blocks, b => {
      deleteBlock(sc, { blockId: b.blockId });
    });
    forEach(blocks, block => {
      const segment = getters.getPathSegmentById(block.segmentId);
      const hmax = Math.min(...map(segment.path, ([_, y]) => y));
      block.offset > hmax &&
        commit("CHANGE_BLOCK_OFFSET", { blockId: block.blockId, offset: hmax - 10 });
      const blockMesh = insertBlock(sc, depth, segment.path, segment, block);
      dispatch("ADD_BLOCK_DRAG_BEHAVIOR", { mesh: blockMesh, hmax });
    });
    //dispatch("SET_TEXTURES", { S:sc });
  }
};

/**
 * Löscht und baut Tür Meshes neu auf
 *
 */
export const REBUILD_FRONT: storeAction = async ({
  dispatch,
  getters,
  state: {
    general_dimensions: { depth, front_open },
    foreground
  }
}) => {
  const sc = await FancySceneSingleton.getScene();
  forEach(foreground, front => {
    deleteFront(sc, front);
  });
  if (foreground) {
    map(foreground, front => {
      const segment = getters.getPathSegmentById(front.segmentId);
      insertFront(sc, depth, segment.path, front_open, segment, front);
    });
  }
  await dispatch("CHECK_FRONT_OPEN");
  //dispatch("SET_TEXTURES", { S:sc});
};
export const SEGMENT_ONCLICK_CB: storeAction = async (
  { dispatch, commit, state },
  { id }: { id: string }
) => {
  const sc = await FancySceneSingleton.getScene();

  commit("CHANGE_SELECTED_SEGMENT", { id });
  dispatch("ADD_SELECTED_HIGHLIGHT", { sc });
};
/**
 * Passt die Tür position dem Store Wert an.
 * Löst die Animation zum öffnen aus.
 */
export const CHECK_FRONT_OPEN: storeAction = async ({
  getters,
  dispatch,
  state: {
    general_dimensions: { front_open },
    foreground
  }
}) => {
  const sc = await FancySceneSingleton.getScene();
  forEach(foreground, front => {
    const segment = getters.getSegmentById(front.segmentId);
    const frontMesh = sc.getMeshByName(front.frontId);
    if (frontMesh) frontMesh.rotation.y = 0;
    //if (getters.hasCornerSegment) {
    if (front_open) {
      if (frontMesh) frontMesh.setEnabled(false);
    } else {
      if (frontMesh) frontMesh.setEnabled(true);
    }
    /*} else {
      let value = (-1 * Math.PI) / 2.2;
      if (!frontMesh) throw new Error("no FrontMesh found");
      if (front.kind !== EFrontType.STANDARD_DOOR_R) {
        value *= -1;
        if (front.kind === EFrontType.STANDARD_DOOR_L) {
          frontMesh.setPivotPoint(new Vector3(segment.width - 2, 0, 0));
        }
      }
      if (frontMesh) {
        if (front_open) {
          dispatch("START_FRONT_ANIMATION", { value: value, frontMesh: frontMesh });
        } else {
          frontMesh.rotation.y = 0;
        }
      }
    }*/
  });
};

export const START_FRONT_ANIMATION: storeAction = async (
  {
    getters,
    state: {
      general_dimensions: { front_open },
      foreground
    }
  },
  { value, frontMesh }: { value: number; frontMesh: Mesh }
) => {
  const sc = await FancySceneSingleton.getScene();
  var yRot = new Animation(
    "yRot",
    "rotation.y",
    5,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );
  const keyFramesR = [
    {
      frame: 0,
      value: 0
    },
    {
      frame: 5,
      value: value
    }
  ];
  yRot.setKeys(keyFramesR);
  sc.beginDirectAnimation(frontMesh, [yRot], 0, 5, false);
  frontMesh.rotation.y = value;
};
/**
 * Fügt Drag Behavior zu Blöcken hinzu.
 * Setzt das Offset eines Blocks in 10er Schritten neu,
 * oder entfernt es wenn es außerhalb des Segments gedragged wird
 * @param {*} { dispatch, commit }
 * @param {{ mesh: Mesh; hmax: number }} { mesh, hmax }
 */
export const ADD_BLOCK_DRAG_BEHAVIOR: storeAction = (
  { dispatch, commit },
  { mesh, hmax }: { mesh: Mesh; hmax: number }
) => {
  const drag = new PointerDragBehavior({ dragAxis: new Vector3(0, 1, 0) });
  mesh.addBehavior(drag);
  drag.onDragEndObservable.addOnce(() => {
    const newOffset = mesh.position.y;
    //'isDisposing' wird gesetzt wenn ein Mesh gerade entfernt wird
    //Notwendig, da das System eventuell versucht das entfernte Mesh abzurufen
    if (!(mesh as any).isDisposing) {
      if (newOffset > hmax - 5 || newOffset < 9) {
        (mesh as any).isDisposing = true;
        commit("REMOVE_BLOCK", { blockId: mesh.name });
        mesh.dispose();
      } else {
        commit("CHANGE_BLOCK_OFFSET", { blockId: mesh.name, offset: round(mesh.position.y, -1) });
        dispatch("REBUILD_BLOCKS");
      }
    }
  });
};

export const UNLINK_MESH: storeAction = async (_, { name }: { name: string }) => {
  const sc = await FancySceneSingleton.getScene();
  const mesh = sc.getMeshByName(name);
  if (mesh) {
    const child = first(mesh.getChildMeshes());
    if (child && mesh.parent) {
      child.parent = mesh.parent;
    }
  }
};

export const SET_TEXTURES: storeAction = async _ => {
  const sc = await FancySceneSingleton.getScene();
  const holz = new StandardMaterial("holz", sc);
  holz.diffuseTexture = new Texture(
    "https://d33wubrfki0l68.cloudfront.net/1ae878f94021e932ba1a581038214311db59613a/835df/img/resources/textures_thumbs/albedo.png.jpg",
    sc
  );
  /*const myMaterial = new StandardMaterial("myMaterial", S);
  const myColor = new Color3(0.51, 0.32, 0.244);
  myMaterial.diffuseColor = myColor;
  myMaterial.specularColor = new Color3(0.6, 0.6, 0.6);*/
  //myMaterial.emissiveColor = new Color3(0, 0.2, 0);
  forEach(sc.meshes, mesh => {
    mesh.material = holz;
  });
};

/** Highlighten des aktuell selektierten Segments
 * Aktivierung des Highlightlayers fügt Glow hinzu!
 */
export const ADD_SELECTED_HIGHLIGHT: storeAction = (
  { dispatch, getters, state: { segments } },
  { sc }: { sc: Scene }
) => {
  //const highlightLayer = sc.getHighlightLayerByName("hl1");
  //if (!highlightLayer) return;
  forEach(segments, seg => {
    const mesh = sc.getMeshByName(seg.segmentId) as Mesh;
    //highlightLayer.removeMesh(sc.getMeshByName(seg.segmentId) as Mesh);
    mesh.edgesColor = EDGE_COLOR;
    mesh.edgesWidth = 20.0;
  });
  forEach(getters.getSelectedSegments, seg => {
    const mesh = sc.getMeshByName(seg.segmentId) as Mesh;
    if (mesh) {
      //highlightLayer.addMesh(mesh, Color3.FromHexString("#703240"));
      mesh.edgesColor = Color4.FromColor3(Color3.FromHexString("#703240"));
      mesh.edgesWidth = 80.0;
    }
  });
  //highlightLayer.outerGlow = false;
  //highlightLayer.innerGlow = false;
};
