









import { Component, Vue } from "vue-property-decorator";
import { range } from "lodash";
import headerComp from "@/components/headerComp.vue";
import footerComp from "@/components/footerComp.vue";
import cookieConsent from "@/components/cookieConsent.vue";
import "roboto-fontface";
import "roboto-fontface-woff";
import "file-saver";
@Component({
  components: {
    cookieConsent,
    headerComp,
    footerComp
  }
})
export default class App extends Vue {
  revoke() {
    (this.$refs.cookieConsent as any).revokeCookies();
  }
}
