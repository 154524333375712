



















































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Datenschutz extends Vue {}
