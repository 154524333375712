import { GetterTree } from "vuex";
import {
  findIndex,
  groupBy,
  find,
  filter,
  take,
  reduce,
  forEach,
  reverse,
  last,
  first,
  uniqWith,
  isEqual,
  map,
  sortBy,
  uniqueId,
  round,
  zip,
  dropRight,
  drop,
  flatten
} from "lodash";
import { Vector3 } from "babylonjs";
import state, { EBlockType, ESegType, IBlock, ISegment, IState } from "./state";

const getters: GetterTree<typeof state, typeof state> = {
  hasWingSegments: ({ segments }) =>
    find(segments, s => s.kind === ESegType.EXTENSION_SEGMENT) ? true : false,
  hasCornerSegment: ({ segments }) =>
    find(segments, s => s.kind === ESegType.CORNER_SEGMENT) ? true : false,
  hasSingleCorner: (_, { hasWingSegments, hasCornerSegment }) =>
    hasCornerSegment && !hasWingSegments ? true : false,
  isCornerSegment: ({ segments }) => (segmentId: string) => {
    const maybeCorner = find(segments, s => s.segmentId === segmentId);
    if (maybeCorner) {
      return maybeCorner.kind === ESegType.CORNER_SEGMENT ? true : false;
    }
    return false;
  },
  hasDoor: ({ foreground }) => (segmentId: string) =>
    find(foreground, { segmentId }) ? true : false,
  defaultState: () => {
    const state: IState = {
      general_dimensions: {
        depth: 60,
        height_base: 180,
        height_left: 180,
        height_right: 180,
        width_left: 0,
        width_right: 0,
        front_open: false
      },
      segments: [
        {
          kind: ESegType.STANDARD_SEGMENT,
          segmentId: uniqueId("segment"),
          selected: true,
          width: 60,
          innerWidth: 57
        }
      ],
      blocks: [],
      foreground: []
    };
    return state;
  },
  rightSlopeDefaultState: () => {
    const state: IState = {
      general_dimensions: {
        depth: 60,
        height_base: 180,
        height_left: 180,
        height_right: 60,
        width_left: 0,
        width_right: 50,
        front_open: false
      },
      segments: [
        {
          kind: ESegType.STANDARD_SEGMENT,
          segmentId: uniqueId("segment"),
          selected: true,
          width: 60,
          innerWidth: 57
        },
        {
          kind: ESegType.STANDARD_SEGMENT,
          segmentId: uniqueId("segment"),
          selected: true,
          width: 60,
          innerWidth: 57
        }
      ],
      blocks: [],
      foreground: []
    };
    return state;
  },
  leftSlopeDefaultState: () => {
    const state: IState = {
      general_dimensions: {
        depth: 60,
        height_base: 180,
        height_left: 60,
        height_right: 180,
        width_left: 50,
        width_right: 0,
        front_open: false
      },
      segments: [
        {
          kind: ESegType.STANDARD_SEGMENT,
          segmentId: uniqueId("segment"),
          selected: true,
          width: 60,
          innerWidth: 56
        },
        {
          kind: ESegType.STANDARD_SEGMENT,
          segmentId: uniqueId("segment"),
          selected: true,
          width: 60,
          innerWidth: 56
        }
      ],
      blocks: [],
      foreground: []
    };
    return state;
  },
  hasBlocks: ({ blocks }) => (segmentId: string) => (find(blocks, { segmentId }) ? true : false),
  globalWidth: ({ segments }) => reduce(segments, (a, c) => a + c.width, 0),
  getWingWidth: (_, { getWingSegments }) => (kind: ESegType) => {
    const segments = getWingSegments(kind);
    return reduce(segments, (a, c) => a + c.width, 0);
  },
  getBlocksBySegment: ({ blocks }) => groupBy(blocks, b => b.segmentId),
  getBlocksBySegmentId: ({ blocks }) => (segmentId: string) =>
    filter(blocks, b => b.segmentId === segmentId),
  getBlockById: ({ blocks }) => (blockId: string) => find(blocks, { blockId }),
  getDoorBySegment: ({ foreground }) => (segmentId: string) => find(foreground, { segmentId }),
  getCameraFocus: (
    { general_dimensions: { height_base } },
    { getWingSegments, hasWingSegments }
  ) => {
    let tmpw = 0;
    forEach(getWingSegments(ESegType.STANDARD_SEGMENT), s => {
      tmpw += s.width;
    });
    if (hasWingSegments) return new Vector3(tmpw, height_base / 2, 0);
    else return new Vector3(tmpw / 2, height_base / 2, 0);
  },
  getInnerSegmentWidth: ({ segments }, { getSegmentById }) => (segmentId: number) => {
    const seg = getSegmentById(segmentId);
    return segments.length > 1 ? seg.width - 3 : seg.width - 4;
  },
  getWingSegments: ({ segments }) => (kind: ESegType) => filter(segments, s => s.kind === kind),
  getCornerSegment: ({ segments }) => find(segments, s => s.kind === ESegType.CORNER_SEGMENT),
  getSegmentById: ({ segments }) => (segmentId: string) => find(segments, { segmentId }),
  getPathSegmentById: (_, { segmentsWithProfile }) => (segmentId: string) =>
    find(segmentsWithProfile, { segmentId }),
  getSegmentWidthOffset: ({ segments }) => (segmentId: string) => {
    const ind = findIndex(segments, s => s.segmentId === segmentId);
    if (ind < 0) {
      throw new Error(`Unknown SegmentId ${segmentId}`);
    }
    // Der Segment-Offset setzt sich aus zwei Teilen zusammen: Dem ersten Offset
    // welcher der Hälfte der Segmentbreite entspricht um den Ursprung des
    // Segment-Meshes an die rechte Wand zu verschieben und dem zweiten
    // Offset, welcher der summierten Breite aller rechteren Segemente entspricht.
    //
    // Dies positioniert alle Segmente korrekt nebeneinander.
    return reduce(take(segments, ind), (p, s) => p + s.width, 0) + segments[ind].width / 2;
  },
  getBlockOffset: ({ blocks }) => (blockId: string) => {
    const block = find(blocks, s => s.blockId === blockId);
    if (!block) {
      throw new Error(`Unkown blockId ${blockId}.`);
    }
    return block.offset;
  },
  getRailsBySegment: (_, { getBlocksBySegmentId }) => (segmentId: string) => {
    const blocksBySegment = getBlocksBySegmentId(segmentId);
    return filter(blocksBySegment, block => block.kind === EBlockType.RAIL);
  },
  getShelvesBySegment: (_, { getBlocksBySegmentId }) => (segmentId: string) => {
    const blocksBySegment = getBlocksBySegmentId(segmentId);
    return filter(blocksBySegment, block => block.kind === EBlockType.SHELF);
  },
  getDrawersBySegment: (_, { getBlocksBySegmentId }) => (segmentId: string) => {
    const blocksBySegment = getBlocksBySegmentId(segmentId);
    return filter(blocksBySegment, block => block.kind === EBlockType.DRAWER);
  },
  countBlocksbyTypeAndSegment: (_, { getBlocksByTypeAndSegment }) => (
    segmentId: string,
    type: EBlockType
  ) => {
    const blocksByTypeAndSegment = getBlocksByTypeAndSegment(segmentId, type);
    return blocksByTypeAndSegment.length;
  },
  getGlobalSegmentPosition: (_, { getSegmentWidthOffset, globalWidth }) => (segmentId: string) =>
    new Vector3(getSegmentWidthOffset(segmentId) - globalWidth / 2, 75, 0),
  getSelectedSegments: ({ segments }) => filter(segments, s => s.selected),
  // groupBy(    map(segments, (_, n) => reduce(take(segments, n), (p, { dimension }) => p+dimension.width, 0)),    s => s.segmentId    );
  profile: ({ general_dimensions }, { globalWidth }: { globalWidth: number }) => {
    const { height_base, height_left, height_right, width_left, width_right } = general_dimensions;

    if (width_left > globalWidth - width_right) {
      // Schrägen dürfen sich nicht überschneiden
      return [[0, height_base], [globalWidth, height_base]] as const;
    }
    return [
      ...(width_right ? [[0, height_right]] : []),
      [width_right, height_base],
      [globalWidth - width_left, height_base],
      ...(width_left ? [[globalWidth, height_left]] : [])
    ];
  },
  hasLeftSlope: ({ general_dimensions }) => {
    if (
      general_dimensions.width_left === 0 ||
      general_dimensions.height_left === general_dimensions.height_base
    ) {
      return false;
    }
    return true;
  },
  hasRightSlope: ({ general_dimensions }) => {
    if (
      general_dimensions.width_right === 0 ||
      general_dimensions.height_right === general_dimensions.height_base
    ) {
      return false;
    }
    return true;
  },
  topParts: (
    { general_dimensions: { width_left, width_right, depth, height_base } },
    { profile, hasLeftSlope, hasRightSlope, globalWidth }
  ) => {
    return [
      ...(hasLeftSlope
        ? [[2, round(Math.sqrt(width_left * width_left + height_base * height_base), -1), depth]]
        : []),
      [2, globalWidth - width_left - width_right, depth],
      ...(hasRightSlope
        ? [[2, round(Math.sqrt(width_right * width_right + height_base * height_base), -1), depth]]
        : [])
    ];
  },
  wallParts: (
    { general_dimensions },
    {
      segmentsWithProfile
    }: {
      segmentsWithProfile: (ISegment & { path: [number, number][] })[];
    }
  ) => {
    const walls = map(zip(segmentsWithProfile, drop(segmentsWithProfile)), ([curr, next]) => {
      if (!curr) throw new Error();
      const rPath = first(curr.path) as [number, number];
      const lPath = last(curr.path) as [number, number];
      if (!rPath || !lPath) throw new Error();
      if (next) {
        return [[2, rPath[1] - 2, general_dimensions.depth]];
      } else {
        return [
          [2, rPath[1] - 2, general_dimensions.depth],
          [2, lPath[1] - 2, general_dimensions.depth]
        ];
      }
    });
    return flatten(walls);
  },
  baseParts: ({ segments, general_dimensions }) =>
    map(segments, segment => {
      return [2, segment.innerWidth, general_dimensions.depth];
    }),
  baseCoverParts: ({ segments, general_dimensions }) =>
    map(segments, segment => {
      return [2, segment.innerWidth, 8];
    }),
  drawerParts: ({ blocks, general_dimensions: { depth } }, { getSegmentById }) => {
    const drawers = filter(blocks, b => isEqual(b.kind, "DRAWER")) as IBlock[];
    const parts = map(drawers, drawer => {
      const segment = getSegmentById(drawer.segmentId);
      return [
        [1, depth - 2, 10],
        [1, depth - 2, 10],
        [1, segment.width - 7, 10],
        [1, segment.width - 7, 10],
        [1, segment.width - 7, depth - 2]
      ];
    });
    return flatten(parts);
  },
  shelfParts: ({ blocks, general_dimensions: { depth } }, { getSegmentById }) => {
    const shelves = filter(blocks, b => isEqual(b.kind, "SHELF")) as IBlock[];
    const parts = map(shelves, shelf => {
      const segment = getSegmentById(shelf.segmentId);
      return [2, segment.innerWidth, depth - 4];
    });
    return parts;
  },
  railParts: ({ blocks, general_dimensions: { depth } }, { getSegmentById }) => {
    const rails = filter(blocks, b => isEqual(b.kind, "RAIL")) as IBlock[];
    const parts = map(rails, rail => {
      const segment = getSegmentById(rail.segmentId);
      return [segment.innerWidth];
    });
    return parts;
  },
  segmentProfile: ({ segments }, { profile }: { profile: [number, number][] }) => {
    const [_, answer] = reduce(
      segments,
      ([scopedProfile, acc], segment) => {
        // Linkster Wert des letzen segments (rechts vom aktuellen)
        // oder alternativ Seed für den rechtesten segment.
        const [_, prevY] = last(last(acc)) || first(profile)!;

        // Finde Profilpunkte innerhalt des aktuellen `segment`.
        const inScope = filter(scopedProfile, ([x, _]) => x <= segment.width);

        // Punkte zwischen denen die Grade aufgespannt wird,
        // die durch die rechte Kante des `segment` geht.
        const [x1, y1] = find(reverse([...scopedProfile]), ([x, _]) => x <= segment.width)!;
        const [x2, y2] = find(scopedProfile, ([x, _]) => x >= segment.width)!;

        const m = (y2 - y1) / (x2 - x1);
        const b = y2 - m * x2;

        const segmentPath: [number, number][] = [
          // Schnittpunkt der Geradengleichung mit dem linken Rand
          // des aktuellen `segment`.
          //
          // Dieser Fall kann offensichtlich nur eintreten
          // falls `x1 === x2 === segment.width` ist. Weiterhin
          // folgt daraus automatisch `y1 === y2`.
          // in dem Fall brauchen wir natürlich keine Intersection zu berechnen
          // sondern können den Punkt verwenden.
          [segment.width, x1 === x2 ? y1 : m * segment.width + b],
          ...inScope,
          [0, prevY]
        ];

        const minsegment = uniqWith(segmentPath, isEqual);

        return [
          // rescope Profil
          map(scopedProfile, ([x, y]) => [x - segment.width, y]),
          [...acc, reverse(filter(minsegment, ([x, _]) => x >= 0))]
        ] as any; // TODO: Auch dieses any analysieren
      },
      [profile, [] as [number, number][][]] as const
    );
    return answer;
  },
  segmentsWithProfile: (
    { segments },
    { segmentProfile }: { segmentProfile: [number, number][][] }
  ) =>
    map(segments, (p, n) => ({
      ...p,
      path: sortBy(segmentProfile[n], o => {
        return o[0];
      })
    })),
  WingSegmentsWithProfile: (_, { segmentProfile, getWingSegments }) => (kind: ESegType) =>
    map(getWingSegments(kind), (p, n) => ({
      ...p,
      path: segmentProfile[n]
    }))
};

export default getters;
