import { Scene, Vector3, Mesh, PointerDragBehavior, MeshBuilder } from "babylonjs";
import { filter, forEach, first, last, sortBy } from "lodash";
import { IBlock, ISegment, EBlockType } from "../store/state";
import { calcCuboidMesh, calcDrawerMesh } from "@/helpers/customMeshBuilder.ts";

export function insertBlock(
  S: Scene,
  depth: number,
  path: [number, number][],
  { segmentId, innerWidth, width }: Pick<ISegment, "segmentId" | "innerWidth" | "width">,
  { blockId, offset, kind }: Pick<IBlock, "blockId" | "offset" | "kind">
) {
  const pathtmp = sortBy(path, o => o[0]);
  const w = last(pathtmp)![0];
  if (!w) throw new Error("invalid path");
  const sMesh = S.getMeshByName(segmentId);
  let blockMesh!: Mesh;
  switch (kind) {
    case EBlockType.SHELF:
      blockMesh = calcCuboidMesh(S, 2, innerWidth + 1, depth - 4) as Mesh;
      break;
    case EBlockType.RAIL:
      blockMesh = createRail({ S, width: innerWidth + 1, depth });

      break;
    case EBlockType.DRAWER:
      blockMesh = calcDrawerMesh(S, w - 7, depth - 2) as Mesh;
      width - innerWidth !== 4 ? (blockMesh.position.x += 1) : (blockMesh.position.x += 0);
  }
  blockMesh.parent = sMesh;
  blockMesh.name = blockId;
  blockMesh.position.y = offset;
  width - innerWidth !== 4 ? (blockMesh.position.x += 1) : (blockMesh.position.x += 2);

  blockMesh.position.z += 2;
  //blockMesh.getBoundingInfo().centerOn(new Vector3((width/2), offset, (depth/2)))
  return blockMesh;
}
/*export function  positionBlock(
  S: Scene,
  depth: number,
  path: [number, number][],
  { segmentId, innerWidth, width }: Pick<ISegment, "segmentId" | "innerWidth" | "width">,
  { blockId, offset, kind }: Pick<IBlock, "blockId" | "offset" | "kind">
) {

}*/
export function createRail({ S, width, depth }: { S: Scene; width: number; depth: number }) {
  const railMesh = MeshBuilder.CreateCylinder("rail", { height: width, diameter: 3 }, S);
  railMesh.rotate(new Vector3(0, 0, 1), (-1 * Math.PI) / 2, BABYLON.Space.LOCAL);
  railMesh.position.z = (1 / 2) * depth;
  railMesh.position.x = (1 / 2) * width;
  railMesh.bakeCurrentTransformIntoVertices();
  return railMesh;
}

export function deleteSegmentBlocks(blocks: Mesh[], segmentId: string) {
  forEach(filter(blocks, b => b.name === segmentId), block => {
    block.dispose();
  });
}
export function deleteBlock(S: Scene, { blockId }: Pick<IBlock, "blockId">) {
  const maybeMesh = S.getMeshByName(blockId);
  if (maybeMesh) {
    (maybeMesh as any).isDisposing = true;
    maybeMesh.dispose();
  }
}
