






import { Component, Vue } from "vue-property-decorator";
import StartConfig from "@/components/startconfig.vue"; // @ is an alias to /src

@Component({
  components: {
    StartConfig
  }
})
export default class Home extends Vue {}
