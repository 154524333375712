





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { ESegType } from "../store/state";
import { Route } from "vue-router";
@Component
export default class baseGeometry extends Vue {
  @Action RESET_STATE!: (type: { type: string }) => void;
  @Action COMPLETE_REBUILD!: () => void;

  items = [
    {
      img: "standard.png",
      action: "standard",
      isActive: false
    },
    {
      img: "leftSlope.png",
      action: "slopeLeft",
      isActive: false
    },
    {
      img: "rightSlope.png",
      action: "slopeRight",
      isActive: false
    }
  ];
  setActive(action: string, active: boolean) {
    this.items.forEach(item => {
      item.isActive = item.action === action ? true : false;
    });
  }
  Start(type: string) {
    this.$store.commit("RESET_STATE", { type });
    this.COMPLETE_REBUILD();
    this.$router.push(`/Step/1`);
  }
}
