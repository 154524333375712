import { storeAction } from "../storeHelper";
import { FancySceneSingleton } from "@/helpers/sceneSingleton";
import { ESegType, EFrontType, IState, ISegment } from "../state";
import { first, isEmpty, forEach, zip, drop } from "lodash";
import { deleteFront } from "@/helpers/front";
import { deleteSegment } from "@/helpers/segment";
export const CHANGE_GENERAL_DIMENSION: storeAction = async (
  { dispatch, state, commit },
  payload: Partial<IState["general_dimensions"]>
) => {
  commit("CHANGE_GENERAL_DIMENSIONS", {
    ...state.general_dimensions,
    ...payload
  });
  await dispatch("COMPLETE_REBUILD");
};

/**
 *
 * Entscheidet welche Art Segment hinzugefügt wird
 *
 */
export const ADD_SEGMENT: storeAction = ({ dispatch, commit }, { SType }: { SType: ESegType }) => {
  switch (SType) {
    case ESegType.STANDARD_SEGMENT:
      commit("ADD_SEGMENT", {
        SKind: SType
      });
      break;
    case ESegType.EXTENSION_SEGMENT:
      dispatch("ADD_EXTENSION_SEGMENT", { SType });
      break;
    case ESegType.CORNER_SEGMENT:
      break;
    default:
      const typeCheck: never = SType;
  }
  dispatch("SET_INNER_WIDTH");
};

export const ADD_FRONT: storeAction = (
  { getters, dispatch, commit },
  { segmentId, SType, FType }: { segmentId: string; SType: ESegType; FType: EFrontType }
) => {
  if (getters.getDoorBySegment(segmentId)) {
    commit("CHANGE_FRONT_TYPE", { segmentId, FType });
  } else {
    commit("ADD_FRONT", { segmentId, segKind: SType, kind: FType });
  }
};
/**
 *
 * Löscht Segment und dazugehörige Tür und Blöcke
 * Setzt Parent Reihenfolge neu,
 * damit es nicht zu Löschanomalien kommt.
 */
export const REMOVE_SEGMENT: storeAction = async (
  { dispatch, getters, commit },
  { segmentId }: { segmentId: string }
) => {
  const sc = await FancySceneSingleton.getScene();
  if (!getters.isCornerSegment(segmentId)) {
    commit("REMOVE_SEGMENT", { segmentId });
    commit("REMOVE_SEGMENT_BLOCKS", { segmentId });
    commit("REMOVE_SEGMENT_FRONT", { segmentId });
    dispatch("CHECK_SINGLE_CORNER");
    dispatch("UNLINK_MESH", { segmentId });
    await dispatch("COMPLETE_REBUILD");
  }
};
export const CHECK_SINGLE_CORNER: storeAction = async ({ dispatch, getters, commit }) => {
  if (getters.hasSingleCorner) {
    const cornerSegment = getters.getCornerSegment.segmentId;
    commit("CHANGE_SEGMENT_KIND", { segmentId: cornerSegment, kind: ESegType.STANDARD_SEGMENT });
    commit("CHANGE_WIDTH", {
      id: cornerSegment,
      width: 60
    });
  }
};
export const REMOVE_FRONT: storeAction = async (
  { dispatch, getters, commit },
  { segmentId }: { segmentId: string }
) => {
  const sc = await FancySceneSingleton.getScene();
  const front = getters.getDoorBySegment(segmentId);
  commit("REMOVE_SEGMENT_FRONT", { segmentId });
  deleteFront(sc, front);
};
export const CHANGE_WIDTH: storeAction = async (
  { dispatch, commit },
  { segmentId, width }: { segmentId: string; width: number }
) => {
  commit("CHANGE_WIDTH", {
    id: segmentId,
    width: width
  });
  dispatch("SET_INNER_WIDTH");
};
export const SET_INNER_WIDTH: storeAction = async ({ state, dispatch, getters, commit }) => {
  dispatch("SET_WING_INNER_WIDTH", {
    segments: getters.getWingSegments(ESegType.STANDARD_SEGMENT)
  });
  const extension = getters
    .getWingSegments(ESegType.EXTENSION_SEGMENT)
    .unshift(getters.getCornerSegment);
  if (!isEmpty(extension)) {
    dispatch("SET_WING_INNER_WIDTH", {
      segments: extension
    });
  }
};
export const SET_WING_INNER_WIDTH: storeAction = async (
  { dispatch, getters, commit },
  { segments }: { segments: ISegment[] }
) => {
  if (segments.length === 1) {
    commit("SET_INNER_WIDTH", {
      segmentId: segments[0].segmentId,
      innerWidth: segments[0].width - 4
    });
  } else {
    forEach(zip(segments, drop(segments)), ([curr, next], n) => {
      if (!next || n === 0) {
        commit("SET_INNER_WIDTH", {
          segmentId: curr!.segmentId,
          innerWidth: curr!.width - 3
        });
      } else {
        commit("SET_INNER_WIDTH", {
          segmentId: curr!.segmentId,
          innerWidth: curr!.width - 2
        });
      }
    });
  }
};
/**
 *
 * Fügt Automatisch ein Ecksegment
 * zum ersten Extension Segment hinzu
 *
 */
export const ADD_EXTENSION_SEGMENT: storeAction = (
  { commit, getters },
  { SType }: { SType: ESegType }
) => {
  // TODO: ADD_SEGMENT-Mutation Logik in ADD_SEGMENT-Action auskapseln.
  // Mögliche Syntax: ADD_SEGMENT({ isExtension: true });
  if (isEmpty(getters.getWingSegments(ESegType.CORNER_SEGMENT))) {
    commit("ADD_SEGMENT", {
      SKind: ESegType.CORNER_SEGMENT,
      width: 120
    });
  }
  commit("ADD_SEGMENT", {
    SKind: SType
  });
};
export const RESET_STATE: storeAction = ({ commit, getters }, { type }: { type: string }) => {
  switch (type) {
    case "standard":
      commit("RESET_STATE", {
        type
      });
      break;
    case "slopeLeft":
      commit("RESET_STATE", {
        type
      });
      break;
    case "slopeRight":
      commit("RESET_STATE", {
        type
      });
      break;
  }
};
