















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ESegType } from "../store/state";
import { Route } from "vue-router";
import baseGeometry from "@/components/baseGeometry.vue";

import scene from "@/components/Scene.vue";
@Component({
  components: {
    baseGeometry
  }
})
export default class StartConfig extends Vue {}
