import { CubeMapToSphericalPolynomialTools, Scene, UtilityLayerRenderer } from "babylonjs";
import * as GUI from "babylonjs-gui";

export class SceneSingleton {
  private static Scene?: Scene;
  private static UI?: GUI.AdvancedDynamicTexture;
  constructor() {
    throw new Error("Use [set/get]Scene directly");
  }

  static setScene(s: Scene) {
    this.Scene = s;
  }

  static getScene() {
    return this.Scene;
  }

  static setUI(UI: GUI.AdvancedDynamicTexture) {
    this.UI = UI;
  }

  static getUI() {
    return this.UI;
  }
}

type DeferredPromise<T> = Promise<T> & {
  resolve: (Value: T | PromiseLike<T>) => void;
  reject: (Reason: any) => void;
};

/**
 * Erstellt ein deferred Promise.
 * Dies ist in der Regel ein Anti-Pattern und sollte nur verwendet
 * werden, wenn es unausweichlich ist.
 * Eine deferred Promise ist ein Promise welches extern resolved
 * (oder rejected) werden kann.
 *
 * @template T Typ des Rückgabewertes des Promise
 * @returns Ein deferred Promise.
 */
const CreateDeferredPromise = <T>() => {
  let Res: false | DeferredPromise<T>["resolve"] = false;
  let Rej: false | DeferredPromise<T>["reject"] = false;

  const DPromise = new Promise((Resolve, Reject) => {
    Res = Resolve;
    Rej = Reject;
  }) as DeferredPromise<T>;

  if (Res === false || Rej === false) {
    throw new Error(`Could not construct a deferred Promise.`);
  }

  DPromise.resolve = Res;
  DPromise.reject = Rej;
  return DPromise;
};
export class FancySceneSingleton {
  private static ScenePromise: DeferredPromise<Scene> = CreateDeferredPromise();
  private static UIPromise: DeferredPromise<GUI.AdvancedDynamicTexture> = CreateDeferredPromise();
  static setScene(Scene: Scene) {
    FancySceneSingleton.ScenePromise.resolve(Scene);
  }

  static async getScene() {
    return FancySceneSingleton.ScenePromise;
  }
  static setUI(UI: GUI.AdvancedDynamicTexture) {
    FancySceneSingleton.UIPromise.resolve(UI);
  }

  static async getUI() {
    return FancySceneSingleton.UIPromise;
  }
  static reset() {
    FancySceneSingleton.ScenePromise = CreateDeferredPromise();
    FancySceneSingleton.UIPromise = CreateDeferredPromise();
  }
}
