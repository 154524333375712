import { Vector2, Scene, Vector3, MeshBuilder, Color3 } from "babylonjs";
import { toString } from "lodash";
import { calcScale } from "@/helpers/customMeshBuilder";
import * as GUI from "babylonjs-gui";

export function calcDiagonalPoint({ p1, p2, w }: { p1: Vector2; p2: Vector2; w: number }) {
  const m = (p2.y - p1.y) / (p2.x - p1.x);
  return m * w;
}
export function clearScene(s: Scene) {
  while (s.meshes.length) {
    (s.meshes[0] as any).isDisposing = true;
    s.meshes[0].dispose(true);
  }
}

export function createScale(
  s: Scene,
  points: Vector3[],
  UI: GUI.AdvancedDynamicTexture,
  txtOffset: Vector3
) {
  const orientation = new Vector3(
    points[1].x - points[0].x,
    points[1].y - points[0].y,
    points[1].z - points[0].z
  );
  const line = calcScale(s, points, orientation);
  line.color = new Color3(0, 0, 0);
  const txt = new GUI.TextBlock();
  txt.text = toString(orientation.x + orientation.y + orientation.z);
  UI.addControl(txt);
  txt.linkWithMesh(line);
  txt.linkOffsetX = txtOffset.x;
  txt.linkOffsetY = txtOffset.y;
}
