import { ActionTree } from "vuex";
import state from "./state";

import {
  COMPLETE_REBUILD,
  REBUILD_SEGMENTS,
  REBUILD_BLOCKS,
  REBUILD_FRONT,
  CHECK_FRONT_OPEN,
  ADD_BLOCK_DRAG_BEHAVIOR,
  SET_TEXTURES,
  ADD_SELECTED_HIGHLIGHT,
  START_FRONT_ANIMATION,
  RENDER_SCALE,
  UNLINK_MESH,
  SEGMENT_ONCLICK_CB
} from "./actions/sceneActions";

import {
  CHANGE_GENERAL_DIMENSION,
  ADD_SEGMENT,
  REMOVE_SEGMENT,
  ADD_EXTENSION_SEGMENT,
  ADD_FRONT,
  REMOVE_FRONT,
  CHECK_SINGLE_CORNER,
  RESET_STATE,
  SET_WING_INNER_WIDTH,
  SET_INNER_WIDTH,
  CHANGE_WIDTH
} from "./actions/stateActions";

const actions: ActionTree<typeof state, typeof state> = {
  COMPLETE_REBUILD,
  REBUILD_SEGMENTS,
  REBUILD_BLOCKS,
  REBUILD_FRONT,
  CHECK_FRONT_OPEN,
  ADD_BLOCK_DRAG_BEHAVIOR,
  CHANGE_GENERAL_DIMENSION,
  ADD_SEGMENT,
  REMOVE_SEGMENT,
  ADD_EXTENSION_SEGMENT,
  SET_TEXTURES,
  ADD_SELECTED_HIGHLIGHT,
  START_FRONT_ANIMATION,
  ADD_FRONT,
  REMOVE_FRONT,
  RENDER_SCALE,
  CHECK_SINGLE_CORNER,
  UNLINK_MESH,
  RESET_STATE,
  SET_WING_INNER_WIDTH,
  SET_INNER_WIDTH,
  SEGMENT_ONCLICK_CB,
  CHANGE_WIDTH
};
export default actions;
