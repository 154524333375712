import {
  Scene,
  Vector2,
  ActionManager,
  ExecuteCodeAction,
  Mesh,
  Vector3,
  StandardMaterial
} from "babylonjs";
import {
  map,
  reduce,
  zip,
  drop,
  dropRight,
  flatten,
  concat,
  last,
  flatMap,
  remove,
  sortBy,
  first,
  isEmpty,
  forEach,
  filter
} from "lodash";
import { ISegment, ESegType } from "../store/state";
import {
  calcCuboidMesh,
  calcTopMesh,
  calcBackMesh,
  //calcWalls,
  calcRightWall,
  calcLeftWall,
  renderEdge
} from "@/helpers/customMeshBuilder.ts";

import { EDGE_COLOR } from "@/helpers/constants";
export {};

export function createSegmentMeshes(
  s: Scene,
  segmentsWithPath: (ISegment & { path: [number, number][] })[],
  depth: number,
  width: (segmentId: string) => number
) {
  forEach(
    segmentsWithPath,
    seg => [createSegmentMesh(s, seg, seg.path, depth, width(seg.segmentId)), seg] as const
  );
  createSegmentWalls(s, segmentsWithPath, depth);
}
export function createSegmentWalls(
  s: Scene,
  segmentsWithPath: (ISegment & { path: [number, number][] })[],
  depth: number
) {
  forEach(zip(segmentsWithPath, drop(segmentsWithPath)), ([curr, next], n) => {
    if (!curr) throw new Error("Wrong current path");
    const groupedPath = zip(dropRight(curr.path), drop(curr.path));
    const groupedNextPath = next ? zip(dropRight(next.path), drop(next.path)) : undefined;
    const lPath = last(groupedPath) as [number, number][];
    const rPath = first(groupedPath) as [number, number][];
    const segmentMesh = s.getMeshByName(curr.segmentId);
    if (!lPath || !rPath) throw new Error("Wrong Path");

    if (next) {
      const groupedNextPath = zip(dropRight(next.path), drop(next.path));
      const rNextPath = first(groupedNextPath) as [number, number][];
      const nextSegmentMesh = s.getMeshByName(next.segmentId);
      const rHalfWall = calcRightWall(s, rNextPath, 1, depth - 2);
      if (next.kind == ESegType.CORNER_SEGMENT) {
        const lHalfWall = calcLeftWall(s, lPath, 2, depth - 2);
        lHalfWall.parent = segmentMesh;
        lHalfWall.position.z += 2;
      }
      rHalfWall.parent = nextSegmentMesh;
      rHalfWall.position.x -= 1;
      rHalfWall.position.z += 2;
    } else {
      let lWall = calcLeftWall(s, lPath, 2, depth - 2);
      lWall.parent = segmentMesh;
      lWall.position.z += 2;
    }
    if (curr.kind == ESegType.CORNER_SEGMENT || n == 0) {
      const rWall = calcRightWall(s, rPath, 2, depth - 2);
      rWall.parent = segmentMesh;
      rWall.position.z += 2;
    }
  });
}
export function createSegmentMesh(
  s: Scene,
  { segmentId, kind }: Pick<ISegment, "segmentId" | "kind">,
  path: [number, number][],
  depth: number,
  width: number
) {
  const d = depth;
  const sortedPath = sortBy(path, o => o[0]);
  const leftPath = last(sortedPath);
  const rightPath = first(sortedPath);
  if (!leftPath) throw new Error(`no left path found`);
  if (!rightPath) throw new Error(`no right path found`);

  const back = calcBackMesh(s, sortedPath, depth) as Mesh;
  const top = calcTopMesh(s, sortedPath, depth) as Mesh;
  const base = calcCuboidMesh(s, 2, width + 1, d - 3);
  const base_cover = calcCuboidMesh(s, 8, width + 1, 2);
  const backInvisible = calcBackMesh(s, sortedPath, depth) as Mesh;
  backInvisible.position.z += 1;
  var mat = new StandardMaterial("mat", s);
  mat.alpha = 0;
  backInvisible.material = mat;
  base.position.x += 1;
  base.position.z += 2;
  base_cover.position.x += 1;
  base_cover.position.z = depth - 3.5;
  //const walls = calcWalls(s, sortedPath, 2, d);
  base.position.y += 8;
  const merged = Mesh.MergeMeshes([base, base_cover, top]);
  if (!merged) throw new Error("merged Mesh null");
  merged.name = segmentId;
  back.parent = merged;
  renderEdge(merged);
  merged.addChild(backInvisible);
  return merged;
}
/**
 *
 *
 * @export
 * @param {Scene} Scene
 * @param {{ segmentId: ISegment["segmentId"]; path: [number, number][]; depth: number; height: number }} {
 *     segmentId,
 *     path,
 *     depth,
 *     height
 *   }
 * @returns
 */

export function deleteSegment(S: Scene, { segmentId }: Pick<ISegment, "segmentId">) {
  const maybeMesh = S.getMeshByName(segmentId);
  if (maybeMesh) {
    maybeMesh.dispose();
  }
}

/**
 *
 *
 * @export
 * @param {Mesh[][]} Korpus
 * @param {Scene} s
 * @param {(korpus: Mesh, index: number) => void} [korpusCb]
 * @returns
 */
export function makeSegmentsClickable(
  S: Scene,
  segments: ISegment[],
  SegmentCb: (index: string) => void
) {
  forEach(segments, segment => {
    const sMesh = S.getMeshByName(segment.segmentId);
    if (!sMesh) {
      throw new Error(`Mesh not found in "makeSegmentsClickable".`);
    }
    sMesh.actionManager = new ActionManager(S);
    sMesh.actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnLeftPickTrigger, function() {
        SegmentCb(segment.segmentId);
      })
    );
    forEach(sMesh.getChildMeshes(), child => {
      child.actionManager = new ActionManager(S);
      child.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnLeftPickTrigger, function() {
          SegmentCb(segment.segmentId);
        })
      );
    });
  });
}

/**
 * Positioniert zusätzlich ein Ecksegment zum ersten Extension Segment
 *
 * @export
 * @param {Scene} s Scene
 * @param {ISegment[]} segments Array aller Segmente
 */
export function positionSegmentstoParent(s: Scene, segments: ISegment[]) {
  reduce(segments, (p, c) => {
    const pMesh = s.getMeshByName(p.segmentId);
    const cMesh = s.getMeshByName(c.segmentId);

    const pWidth = p.width;
    if (!cMesh) return p;
    cMesh.parent = pMesh;
    cMesh.position.x = pWidth;
    return c;
  });
}

/**
 * Positioniert ein Corner-Segment.
 *
 * @export
 * @param {Scene} s Scene mit den Meshes.
 * @param {ISegment} parent Letztes Segment der Base.
 * @param {ISegment} child Erstes Segment der Extension.
 * @param {ISegment} cSeg Segment der Corner.
 */
export function positionCornerSegment(
  s: Scene,
  parent: ISegment,
  child: ISegment,
  cSeg: ISegment,
  depth: number
) {
  const parentMesh = s.getMeshByName(parent.segmentId);
  const childMesh = s.getMeshByName(child.segmentId);
  const cornerMesh = s.getMeshByName(cSeg.segmentId);

  if (!parentMesh || !childMesh || !cornerMesh) {
    throw new Error(`Could not gather all Segments in "positionCornerSegment".`);
  }
  const sideWalls = cornerMesh.getChildMeshes().filter(mesh => mesh.name === "custom");
  if (sideWalls) {
    sideWalls[0].dispose();
  }
  cornerMesh.parent = parentMesh;
  childMesh.parent = cornerMesh;
  cornerMesh.position.x = parent.width + depth;
  cornerMesh.rotate(new Vector3(0, 1, 0), (-1 * Math.PI) / 2, BABYLON.Space.LOCAL);
  childMesh.position.x = cSeg.width;
}
