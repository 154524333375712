import { uniqueId } from "lodash";
import { Scene } from "babylonjs";

export const enum ESegType {
  STANDARD_SEGMENT = "STANDARD_SEGMENT",
  CORNER_SEGMENT = "CORNER_SEGMENT",
  EXTENSION_SEGMENT = "EXTENSION_SEGMENT"
}
export const enum EBlockType {
  SHELF = "SHELF",
  RAIL = "RAIL",
  DRAWER = "DRAWER"
}
export const enum EFrontType {
  STANDARD_DOOR_R = "STANDARD_DOOR_R",
  STANDARD_DOOR_L = "STANDARD_DOOR_L",
  CORNER_DOOR = "CORNER_DOOR"
}
export interface IState {
  //scene?: Scene;
  general_dimensions: {
    depth: number;
    height_base: number;
    height_left: number;
    height_right: number;
    width_left: number;
    width_right: number;
    front_open: boolean;
  };
  segments: ISegment[];
  blocks: IBlock[];

  /** Später für Türen */
  foreground: IFront[];
  // TODO: Materialsystem erdenken
}

export interface ISegment {
  kind: ESegType;
  segmentId: string;
  selected: boolean;
  width: number;
  innerWidth: number;
}

export interface IBlock {
  blockId: string;
  segmentId: string;
  selected: boolean;
  offset: number;
  height: number;
  kind: EBlockType;
}

export interface IFront {
  kind: EFrontType;
  segmentId: string;
  frontId: string;
}

const state: IState = {
  general_dimensions: {
    depth: 60,
    height_base: 180,
    height_left: 180,
    height_right: 180,
    width_left: 0,
    width_right: 0,
    front_open: false
  },
  segments: [
    {
      kind: ESegType.STANDARD_SEGMENT,
      segmentId: uniqueId("segment"),
      selected: true,
      width: 60,
      innerWidth: 56
    }
  ],
  blocks: [],
  foreground: []
};

const fakeState = (state as any) as IState;

export default fakeState;
